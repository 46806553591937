export const views = {
  dev_sessions: {
    dev_sessions_index: {
      action: 'akcja',
      email: 'Adres email',
      logged_in: 'Jesteś już zalogowany',
      name: 'imię',
      password: 'Hasło',
      roles: 'role',
      surname: 'nazwisko',
      title: 'Logowanie'
    }
  },
  empty_view: {
    return: 'Wróć na ekran główny',
    subtitle: '',
    text: 'Nie masz odpowiednich uprawnień aby przejść dalej. Twoje konto jest w systemie, ale musisz zostać zaakceptowny przez administratora, aby móc korzystać z aplikacji.',
    title: 'Brak uprawnień'
  },
  institutions: {
    cleared: 'Uczelnia została wyczyszczona z danych',
    created: 'Uczelnia dodana pomyślnie',
    dropped: 'Uczelnia została poprawnie usunięta',
    list: {
      application_version: 'Wersja aplikacji',
      base_cleaning: 'Czyszczenie bazy',
      base_cleaning_confirmation: 'Czy na pewno chcesz wyczyścić bazę uczelni',
      base_deleting: 'Usuwanie bazy',
      base_deleting_confirmation: 'Czy na pewno chcesz usunąć bazę uczelni',
      base_deleting_second_confirmation: 'Dane zostaną bezpowrotnie usunięte. Jesteś pewien?',
      base_updating: 'Aktualizacja słowników',
      base_updating_confirmation: 'Czy na pewno chcesz zaktualizować słowniki danej uczelni?',
      contract_end_date: 'Data końca umowy',
      cyclical_evaluation: 'Cykliczna ewaluacja',
      fields: {
        name: 'Nazwa uczelni',
        sub_domain: 'Subdomena',
        manager_email: 'Adres email administratora jednostki',
        identifier: 'Identyfikator',
        secret: 'Sekretne hasło',
        origin: 'Źródło',
        installation_version: 'Wersja aplikacji',
        issuer: 'Emitent',
        end_session_endpoint: 'Koniec sesji',
        authorization_endpoint: 'Punkt końcowy autoryzacji',
        token_endpoint: 'Punkt końcowy Tokenu',
        user_info_endpoint: 'Informacje o użytkowniku',
        scope: "Zakres",
        login: "Login",
        password: "Hasło",
        institutionId: "ID instytucji",
        config: "Konfiguracja",
        url_for_login: 'URL do logowania',
        url_to_download_the_structure: 'URL do pobierania struktury',
        url_to_download_structure_node: 'URL do pobierania węzła struktury',
        url_to_download_the_number_of_students: 'URL do pobierania ilości studentów',
        url_to_generate_the_package: 'URL do generowania pakietu',
        url_to_check_package_status: 'URL do sprawdzania statusu pakietu',
        url_to_download_the_package: 'URL do pobierania pakietu'
      },
      configuration_types: {
        demo: 'Konfiguracja Demo',
        prod: 'Konfiguracja Produkcyjna'
      },
      go_into_academy: 'Przejdź na uczelnię',
      installation_versions: {
        basic: 'Wersja podstawowa',
        extended: 'Wersja rozszerzona'
      },
      locked: 'Blokada',
      name: 'Nazwa uczelni',
      new: 'Dodaj uczelnie',
      no_records: 'Brak uczelni',
      only_last: 'Tylko ostatnie',
      polon_version: 'Wersja POL-on',
      replication_date: 'Data replikacji',
      subtitle: '',
      title: 'Lista uczelni',
    },
    updated: 'Uczelnia zaktualizowana pomyślnie'
  },
  login: {
    header: 'Integracja Polon',
    login_btn: 'Zaloguj się'
  },
  redirect_view: {
    subtitle: '',
    text: 'Nie udało się się przekierować. Spróbuj ponownie.',
    title: 'Przekierowywanie'
  },
  users: {
    list: {
      subtitle: '',
      surname: 'nazwisko',
      title: 'Użytkownicy',
      add_user: 'Dodaj użytkownika',
      adding_user: 'Dodawanie użytkownika',
      delete_user: 'Usuń użytkownika',
      delete_user_confirm: 'Usuwanie użytkownika',
      delete_user_confirm_text: 'Czy na pewno chcesz usunąć użytkownika?',
      delete_user_success: 'Pomyślnie usunięto użytkownika',
      edit_roles: 'Edycja ról',
      edit_user: 'Edytuj użytkownika',
      edit_user_failure: 'Użytkownik nie został zedytowany',
      edit_user_success: 'Użytkownik został zedytowany pomyślnie',
      editing_user: 'Edytowanie użytkownika',
      email: 'Adres email',
      fields: {
        album_number: 'Numer albumu',
        status: 'Status',
        unit: 'Jednostka',
        created_at: 'Data stworzenia konta',
        email: 'Adres email',
        full_name: 'Imię i nazwisko',
        last_login: 'Ostatni czas logowania',
        roles: 'Rola użytkownika'
      },
      filter_users: 'Filtruj użytkowników',
      users_list: 'Lista użytkowników',
      first_name: 'Imię',
      by_names: 'Imię i nazwisko',
      no_records: 'Niestety nie odnaleziono użytkowników spełniających warunki wyszukiwania',
      no_role: 'Brak roli',
      role: 'Rola',
      sort_by: 'Sortuj według',
      sort_by_asc: 'Sortowanie rosnące',
      sort_by_desc: 'Sortowanie malejące',
      roles: {
        systemadministrator: 'Administrator systemu',
        systemmanager: 'Menadżer systemu',
        unitadministrator: 'Administrator jednostki',
        employee: 'Pracownik'
      }
    }
  },
  imports: {
    list: {
      select_all: 'Zaznacz wszystkie',
      export_csv: 'Wyeksportuj CSV',
      title: 'Eksporty',
      fields: {
        full_name: 'Nazwa użytkownika',
        import_date: 'Import do systemu',
        polon_import_start: "Start eksportu",
        polon_import_end: 'Koniec eksportu',
        import_status: 'Status'
      },
      selected: "zaznaczonych elementów",
      status: {
        canceled: 'Anulowano',
        exported: 'Wyeksportowano',
        export_failed: 'Eksport się nie powiódł',
        failed: 'Eksport się nie powiódł',
        import_failed: 'Import się nie powiódł',
        in_progress: 'W toku',
        imported: 'Zaimportowano',
        partially_exported: 'Wyeksportowano część',
        queued: 'W kolejce',
        sent_to_export: 'Przekazano do eksportu'
      },
      exports_list: 'Lista eksportów',
      cancel_import: 'Anuluj import',
      cancel_import_warning: 'Operacja anulowania importu jest nieodwracalna',
      import_errors: {
        import_errors_export_failure: "Pobranie błędów eksportu zakończony niepowodzeniem",
        import_errors_export_success: "Błędy eksportu zostały pobrane"
      },
      no_records: 'Niestety nie odnaleziono eksportów spełniających warunki wyszukiwania',
    }
  },
  no_subscription_view: {
    return: 'Wróć na ekran główny',
    subtitle: '',
    text: 'Twoja subskrypcja wygasła, proszę skontaktuj się z administratorem',
    title: 'Subskrypcja wygasła'
  },
  sis_imports: {
    list: {
      canceling_import: 'Anulowanie importu',
      canceling_import_confirmation: 'Czy na pewno chcesz anulować import?',
      exporting_to_polon: 'Eksport studentów do POL-on',
      exporting_to_polon_confirmation: 'Czy na pewno chcesz eksportować studentów do POL-on?',
      fields: {
        import_date: 'Import na dzień',
        import_initialized: 'Inicjalizacja importu',
        import_started: 'Koniec importu'
      },
      import_canceled: 'Poprawnie anulowano dany import',
      imports_list: 'Lista importów',
      no_records: 'Niestety nie odnaleziono importów spełniających warunki wyszukiwania',
      subtitle: '',
      title: 'Lista Importów z SIS',
      wrong_status: 'Twoja akcja nie została wykonana, ponieważ inny użytkownik wykonał akcję na danym imporcie i zmienił jego status'
    }
  },
  students: {
    list: {
      title: 'Studenci',
      show_history: "Pokaż historię zmian",
      download_json: "Pobierz JSON",
      download_polon_json: "Pobierz JSON do POL-on 2.0",
      show_errors: 'Pokaż błąd',
      show_json: 'Pokaż JSON',
      show_polon_json: 'Pokaż JSON do POL-on 2.0',
      no_records: 'Niestety nie odnaleziono studentów spełniających warunki wyszukiwania',
      fields:{
        album_number: 'Nr albumu',
        pesel: 'Pesel',
        document_number: 'Nr dok.toż.',
        document_type: 'Rodzaj dok.toż.',
        name: 'Imię',
        surname: 'Nazwisko',
        status: 'Status',
        other_names: 'Pozostałe imiona',
        last_export_date: 'Ostatni eksport',
        email: 'Email',
        hasPlCard: 'Polski dowód osobisty',
        birthYear: "Rok urodzenia",
        birthCountry: "Kraj urodzenia",
        citizenships: "Obywatelstwa",
        gender: "Płeć",
        originCountry: 'Kraj pochodzenia',
        surnamePrefix: 'Przedrostek nazwiska',
        validFromDate: "Ważne od daty",
        validToDate: "Ważne do daty",
        externalId: 'Zewnętrzny ID',
        documentCountry: 'Kraj dok.toż',
        coLedStudy: 'coLedStudy',
        diplomaNumber: 'Number dyplomu',
        discontinuationDate: "Data zaprzestania",
        educationStartDate: "Data rozpoczęcia edukacji",
        exclusionFromStudiesProcedure: 'Wykluczenie ze studiów',
        graduationDate: 'Data ukończenia studiów',
        placeOfResidence: 'Miejsce zamieszkania',
        professionalTitle: "Profesjonalny tytuł",
        teacherTraining: "Szkolenie nauczyciela",
        admissionType: 'Podstawa przyjęcia studenta',
        exemptionFeeType: "Opłata za zwolnienie",
        financialType: "Rodzaj finansowania",
        albumNumber: "Numer albumu",
        otherNames: "Pozostałe imiona",
        documentNumber: "Numer dokumentu",
        documentType: "Typ dokumentu",
        note: "Notatka",
        level: "Poziom",
        studySemester: "Semestr",
        accumulatedEcts: 'Punkty ECTS',
        academicSemester: "Semestr akademicki",
        academicYear: "Rok akademicki",
        form: 'Forma studiów',
        fieldOfStudyInstanceCode: "fieldOfStudyInstanceCode",
        month: "Miesiąc",
        year: 'Rok'
      },
      models: {
        Student: "Studenta",
        Identification: "Dokument Studenta",
        StudentCourse: "Kurs Studenta",
        FinancialAid: "Pomoc finansową Studenta", 
        StudentSemester: "Semestr Studenta",
        BasesForAdmission: "Podstawę przyjęcia na studia",
        BasesForExemptionFee: "podstawę zwolnienia z opłat"
      },
      status:{
        exported: 'Wysłany',
        partially_exported: 'Wyeksportowano część',
        failed: 'Niewysłany'
      },
      document_type: {
        id_card: 'Dowód osobisty',
        residence_card: 'Karta mieszkańca',
        polish_travel_document_for_foreigner: 'Polski dokument dla cudzoziemca',
        temporal_polish_travel_document_for_foreigner: 'Tymczasowy polski dokument dla cudzoziemca',
        polish_id_card_for_foreigner: 'Polski dowód osobisty dla obcokrajowca',
        passport: 'Paszport'
      },
      before:'Poprzednia wersja',
      after: 'Aktualna wersja',
      record_history: "<i class='far fa-comment-alt' style='margin-right: 1.5rem;'></i>Historia rekordu",
      record_errors: "<i class='far fa-comment-alt' style='margin-right: 1.5rem;'></i>Błędy",
      sis_import_details: "<i class='far fa-comment-alt' style='margin-right: 1.5rem;'></i>Szczegóły Importu",
      students_list: "lista studentów",
      search: 'Szukaj',
      search_tooltip: 'Można użyć fragmentu imienia, nazwiska, PESELu, numeru dokumentu lub ich kombinacji',
      filter_students: 'Filtruj studentów'
    }
  },
  integrations: {
    generate_token: 'Generuj token',
    integration_data: 'Dane integracyjne',
    integration_modal: 'UZUPEŁNIANIE DANYCH DO INTEGRACJI Z POL-ON 2.0',
    integration_modal_show: 'DANE DO INTEGRACJI Z POL-ON 2.0',
    no_token: 'Nie posiadasz jeszcze tokenu integracyjnego',
    polon: "Integracja POL-on 2.0",
    polon_error: 'Niepoprawne dane do integracji z POL-on 2.0',
    show_token: 'Pokaż token',
    sis_configuration: 'Dane konfiguracyjne SIS',
    sis_error: 'Niepoprawne dane do integracji z SIS',
    sis_modal: 'UZUPEŁNIANIE DANYCH DO INTEGRACJI Z SIS',
    sis_success: 'Pomyślna aktualizacja danych do integracji z SIS',
    token: 'Token integracyjny'
  }
}