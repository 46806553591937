import { render, staticRenderFns } from "./SisImportStatus.vue?vue&type=template&id=23b7af9e&scoped=true&"
import script from "./SisImportStatus.vue?vue&type=script&lang=js&"
export * from "./SisImportStatus.vue?vue&type=script&lang=js&"
import style0 from "./SisImportStatus.vue?vue&type=style&index=0&id=23b7af9e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b7af9e",
  null
  
)

export default component.exports